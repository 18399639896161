import api from "./api";

var accessToken = "";
var refreshToken = "";
var Session = (function () {
  var isSessionExist = function () {
    let access = localStorage.getItem("access");
    let refresh = localStorage.getItem("refresh");
    if (access === null || refresh === null) {
      localStorage.clear();
      return false;
    }
    accessToken = access;
    refreshToken = refresh;
    return true;
  };
  var setTokens = function (access, refresh) {
    accessToken = access;
    refreshToken = refresh;
    saveSession();
  };

  var saveSession = function () {
    localStorage.setItem("access", accessToken);
    localStorage.setItem("refresh", refreshToken);
  };

  return {
    setTokens: setTokens,
    saveSession: saveSession,
    isSessionExist: isSessionExist,
  };
})();

export var accessToken;
export var refreshToken;
export default Session;

export var saveSession = function (accessToken, refreshToken) {
  localStorage.setItem("access", accessToken);
  localStorage.setItem("refresh", refreshToken);
};

export var isSessionExist = function () {
  let access = localStorage.getItem("access");
  let refresh = localStorage.getItem("refresh");
  if (window.location.href.includes("/login/")) return false;
  if (access === null || refresh === null) {
    localStorage.clear();
    window.location.href = "/login/";
    return false;
  }
  accessToken = access;
  refreshToken = refresh;
  return true;
};

export const refreshTokenAtStart = async () => {
  let response = await api.post("refresh/", {
    refresh: localStorage.getItem("refresh"),
  });
  if (response.status === 200) {
    if (response.data.hasOwnProperty("access")) {
      saveSession(response.data["access"], localStorage.getItem("refresh"));
      return true;
    } else {
      let refresh = response.data["refresh"];
      let res = await api.post("refresh/", {
        refresh: response.data["refresh"],
      });
      if (res.status === 200) {
        saveSession(res.data["access"], refresh);
        return true;
      } else {
        return false;
      }
    }
  }
};
