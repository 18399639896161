import { createSlice, configureStore } from "@reduxjs/toolkit";

const initialState = {
  username: "",
  firstname: "",
  lastname: "",
  email: "",
  profileimage: "",
  dpimage: "",
  description: "",
  isSelf: true,
};

export const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    fetchUserDetail: (state, action) => {
      state.username = action.payload.username;
      state.profileimage = action.payload.profileimage;
    },
  },
});

export const { fetchUserDetail } = profileSlice.actions;

// export default profileSlice.reducer
export const store = configureStore({
  reducer: {
    profile: profileSlice.reducer,
  },
});
