import React from 'react';
import { Container } from "react-bootstrap";
import "../assets/css/settings.css";

import { ThemeContext } from '../context_api/theme_context';


export default function SettingsPage() {

    return (<Container>
        <section className="sociallink-title">Theme</section>
        <ThemeContext.Consumer >
            {({ theme, switchTheme }) =>
                // <button onClick={switchTheme}>
                <div class="btn-group" role="group">
                    <ThemeToggleSwitch isSelected={theme === "light"} onClick={() => { switchTheme("light") }} >Light</ThemeToggleSwitch>
                    <ThemeToggleSwitch isSelected={theme === "dark"} onClick={() => { switchTheme("dark") }} >Dark</ThemeToggleSwitch>
                </div>

            }
        </ThemeContext.Consumer>
    </Container >);

}

const ThemeToggleSwitch = (props) => {
    return (<button type="button" className={'btn mr-2 ' + (props.isSelected ? " btn-success" : " btn-outline-success")} onClick={props.onClick} style={{ fontSize: 12 }}>
        {props.children}
    </button>);
}