import React, { useEffect, useState } from "react"
import { Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import api, { getHeader } from "../utils/api";
export default function UsersPage() {

    const [users, setUsers] = useState([]);

    const fetchUsers = () => {
        api.get("profile/all/", { headers: getHeader(true) }).then((response) => {
            if (response.status === 200) {
                setUsers(response.data);
            }

        });

    }

    useEffect(() => {
        fetchUsers();
    }, []);
    return <Row>
        {users.map((user) => <PopularTile data={user} />)}
    </Row>
}


const PopularTile = (props) => {
    const data = props.data;
    return (
        <Col lg={6} md={12}>
            <Link to={'/user/' + data.id} style={{ textDecoration: "none" }}>
                < section className="popular-article-item d-flex  align-items-center py-2" >
                    <Image alt="Article Image" src={data.profileimage} className="article-image" />
                    <Col className="title-txt mx-4">
                        <div className="popular-article-title">{data.first_name} {data.last_name}</div>
                        <div className="popular-article-date">@{data.username}</div>
                    </Col>
                    <div className="popular-article-date">{data.email}</div>
                </section >
            </Link >
        </Col>
    );

}