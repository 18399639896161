
import React from 'react';

import ReactPaginate from 'react-paginate';
function PaginationBox({ current, total, onPageChange }) {

    if (total < 2) return <></>;

    return (
        <ReactPaginate
            breakLabel="..."
            nextLabel="next >"
            onPageChange={onPageChange}


            className='pagination'
            pageClassName='page-item'
            pageLinkClassName='page-link'
            pageRangeDisplayed={3}
            pageCount={total}

            initialPage={current}

            activeLinkClassName="page-link"
            activeClassName="active"
            containerClassName="pagination"
            previousClassName='page-item'
            nextClassName='page-item'
            previousLinkClassName='page-link'
            nextLinkClassName='page-link'
            previousLabel="< prev"
            renderOnZeroPageCount={<></>}
        />
    );
}

export default PaginationBox;