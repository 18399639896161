import React, { useEffect, useState } from 'react';
import { Container } from "react-bootstrap";
import "../assets/css/settings.css";
import ReactTooltip from 'react-tooltip';
import { FaRegEdit } from 'react-icons/fa';
import { MdOutlineOpenInNew, MdRemoveCircleOutline } from 'react-icons/md';
import api, { getHeader } from '../utils/api';


export default function SocialsPage() {
    const [facebook, setFacebook] = useState({ "link": "", "name": "facebook" });
    const [instragram, setInstragram] = useState({ "link": "", "name": "instragram" });
    const [twitter, setTwitter] = useState({ "link": "", "name": "twitter" });
    const [linkedin, setLinkedIn] = useState({ "link": "", "name": "linkedin" });

    useEffect(() => { fetchLinks(); }, []);

    const fetchLinks = () => {
        api.get("setting/social_media/").then((response) => {
            var data = response.data;
            setFacebook({ "link": "", "name": "facebook" });
            setInstragram({ "link": "", "name": "instragram" });
            setTwitter({ "link": "", "name": "twitter" });
            setLinkedIn({ "link": "", "name": "linkedin" });
            data.forEach(element => {
                if (element.social_media_name  ===  'facebook') {
                    setFacebook({
                        id: element.id,
                        link: element.social_link,
                        name: element.social_media_name
                    })
                } else if (element.social_media_name  ===  'instragram') {
                    setInstragram({
                        id: element.id,
                        link: element.social_link,
                        name: element.social_media_name
                    })
                } else if (element.social_media_name  ===  'twitter') {
                    setTwitter({
                        id: element.id,
                        link: element.social_link,
                        name: element.social_media_name
                    })
                } else if (element.social_media_name  ===  'linkedin') {
                    setLinkedIn({
                        id: element.id,
                        link: element.social_link,
                        name: element.social_media_name
                    });
                }
            });
        });
    }


    const editSocialLink = (link) => {
        let newLink = prompt("Please enter your " + link.name + " link", link.link);
        if (newLink  ===  null) return;
        if (newLink  ===  "" || newLink  ===  link) {
            alert("Unable to change");
        } else {
            if (link.id  ===  null) {
                api.post("setting/social_media/", {
                    "name": link.name,
                    "link": newLink
                }, { headers: getHeader(true) }).then((response) => {
                    fetchLinks();
                });
            } else {
                api.post("setting/social_media/", {
                    "id": link.id,
                    "name": link.name,
                    "link": newLink
                }, { headers: getHeader(true) }).then((response) => {
                    fetchLinks();
                });

            }
        }
    }
    const deleteSocialLink = (link) => {
        if (window.confirm("Are you sure to remove link?")) {
            api.delete("setting/social_media/?id=" + link.id).then((response) => {
                fetchLinks();
            });
        } else {
        }
    }


    const SocialLink = (props) => {
        return <div className="sociallinkbox m-2">

            <div style={{ height: 40, width: "100%" }}>
                <center>
                    <img alt="Social link" src={props.iconlink} width={40} />
                </center>
            </div>
            <div className='my-3' />
            <div className="sociallinktxt" style={{ height: 20 }} >
                {props.value.link}
            </div>
            <div className="w-100 d-flex text-center justify-content-around my-2" >
                <a href={props.value.link} target="_blank" rel="noopener noreferrer" className="mx-2" data-tip="Open link" data-for='sociallink-tooltip' style={{ color: "#46a0ef" }}>
                    <MdOutlineOpenInNew />
                </a>
                <sociallink className="mx-2" data-tip="Edit" href="#" data-for='sociallink-tooltip' onClick={() => { editSocialLink(props.value) }} style={{ color: "#ffc91a" }}>
                    <FaRegEdit />
                </sociallink>
                <sociallink className="mx-2" data-tip="Remove" href="#"  data-for='sociallink-tooltip' onClick={() => { deleteSocialLink(props.value) }} style={{ color: "#ed2f2f" }}>
                    <MdRemoveCircleOutline />
                </sociallink>
            </div>
        </div >
    }

    return (<Container>
        <section className="sociallink-title">Social Links</section>
        <div className='d-flex flex-wrap w-100 my-2'>
            <ReactTooltip id="sociallink-tooltip" data-type="success" effect="float" />
            <SocialLink iconlink={"https://img.icons8.com/color/48/000000/facebook-circled--v1.png"} value={facebook} />
            {/* <div className='mx-3' /> */}
            <SocialLink iconlink={"https://img.icons8.com/fluency/48/000000/instagram-new.png"} value={instragram} />
            {/* <div className='mx-3' /> */}
            <SocialLink iconlink={"https://img.icons8.com/color/48/000000/twitter-circled--v1.png"} value={twitter} />
            {/* <div className='mx-3' /> */}
            <SocialLink iconlink={"https://img.icons8.com/color/48/000000/linkedin.png"} value={linkedin} />
        </div>

    </Container >);

}
