import React from 'react';

import {  Container, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import '../assets/css/blogstyle.css';
import TextTruncate from 'react-text-truncate';
import { FiEdit, FiExternalLink } from 'react-icons/fi';
import { MdOutlineDelete, MdOutlineSettingsBackupRestore } from 'react-icons/md';
import Popup from 'reactjs-popup';
import TimeAgo from 'react-timeago';
import ReactTooltip from 'react-tooltip';

export default function BlogCard({ blog, onRemove, onFeatureChange }) {
    return <div className="blog_container">
        <div style={{ minHeight: 200 }} >
            <Image src={blog.coverImage} style={{ objectFit: "cover", height: 200, width: "100%" }} />
        </div>
        <Container>
            <section className="blog_title pt-3 d-flex d-row justify-content-between align-items-center">
                <span className='blog_title'>{blog.title}</span>
                <span className='blog_date'>
                    <TimeAgo date={blog.modifiedDate} />
                </span>
            </section>
            <hr className='py-0 my-2' />
            <TextTruncate
                line={5}
                className='blogdescription py-3'
                element="div"
                truncateText="....."
                text={blog.body.replace(/(<([^>]+)>)/ig, '').replace(/((&nbsp;))*/gmi, "")}
                textTruncateChild={null}
            />
            <hr className='py-0 my-2' />
            <div className='mb-3 d-flex'>
                <div className='flex-fill' >
                    <ReactTooltip id="articlelist-tooltip" data-type="success" effect="float" />
                    <a className={blog.isRemoved ? "d-none" : 'p-2'} href={`https://sumiphuyal.com.np/content/${blog.slug}/`} target="_blank" rel="noopener noreferrer" data-tip="Open" data-for='articlelist-tooltip'>
                        <FiExternalLink style={{ color: "#1473E6" }} />
                    </a>
                    <Link className={blog.isRemoved ? "d-none" : 'p-2'} to={"/article/" + blog.slug} data-tip="Edit" data-for='articlelist-tooltip'  >
                        <FiEdit style={{ color: "#FFC300" }} />
                    </Link>
                    <Link className='p-2' to={"#"} data-tip={blog.isRemoved ? "Restore" : "Remove"} data-for='articlelist-tooltip' onClick={() => {
                        onRemove(blog.id, blog.isRemoved);
                    }}  >
                        {blog.isRemoved ? <MdOutlineSettingsBackupRestore /> :
                            <MdOutlineDelete style={{ color: "#e61414" }} />}
                    </Link>
                </div>
                <span className="mx-2" />
                {
                    blog.isRemoved ? <></> :
                        <Popup
                            trigger={blog.isFeatured ? <span className='blogfeatured'>Featured</span>
                                : <span className='notblogfeatured'>Not Featured</span>}
                            modal closeOnEscape overlayStyle={{ zIndex: 9999 }} contentStyle={{ borderRadius: 6, maxWidth: 300 }} position=" center">
                            {close => (<div className="p-2" style={{ maxWidth: 300 }}>
                                <div className={blog.isFeatured ? "text-danger fw-bold" : "text-success fw-bold"}>Sure to {blog.isFeatured ? "remove from feature" : "add to feature this"}?</div>
                                <div className="py-2 d-flex">
                                    <button className="btn-sm btn btn-outline-secondary mx-1" onClick={() => { close(); }} >Cancel</button>
                                    <button className={`btn btn-sm mx-1 ${blog.isFeatured ? "btn-outline-danger" : "btn-outline-success"}`}
                                        onClick={() => {
                                            onFeatureChange(blog.id, blog.isFeatured);
                                            close();
                                        }}
                                    >{blog.isFeatured ? "Yes, remove" : "Yes, add"}</button>
                                </div>
                            </div>)}
                        </Popup>
                }


            </div>
        </Container >
    </div >
}
