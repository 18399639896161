import React, { useEffect } from 'react';
import { useState } from 'react';
import '../assets/css/loginstyle.css';
import '../assets/css/solarsystem.css';
import '../assets/css/stars.css';
import jupiter from '../assets/images/jupiter.png';
import starStyles from '../assets/css/star.module.css'
import api from '../utils/api';
import { Col, Row } from 'react-bootstrap';
import Session from '../utils/user_session';



export default function LoginPage() {

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const login = (username, password) => {
        api.post("login/", {
            "username": username,
            "password": password
        }).then((response) => {
            if (response.status  ===  200) {
                Session.setTokens(response.data['access'], response.data['refresh']);
                window.location.href = "/dashboard/";
            }
        });

    }
    useEffect(() => {
        localStorage.clear();
        document.body.style.overflow = "hidden";
    }, []);

    return (
        <div className="login_background background">
            <div className={starStyles.container}>
                <div className={starStyles.stars}></div>
                <div className={starStyles.twinkling}></div>
            </div>
            <div class='solar_system solar-syst stars'>
                <div class='sun'></div>
                <div class='mercury'></div>
                <div class='venus'></div>
                <div class='earth'></div>
                <div class='mars'></div>
                <div class='jupiter'></div>
                <div class='saturn'></div>
                <div class='uranus'></div>
                <div class='neptune'></div>
                <div class='pluto'></div>
                <div class='asteroids-belt'></div>
            </div>
            <Row className='d-flex form_row'>
                <Col md={6} sm={0}></Col>
                <Col md={6} sm={12} className="p-5 d-flex align-items-center justify-content-center" >
                    <form className='animate__animated animate__fadeIn '>
                        <center>
                            <img alt="Jupiter" src={jupiter} height={160} width={160} />
                        </center>
                        <h3>Login</h3>
                        <label for="username">Username</label>
                        <input type="text" placeholder="Username" id="text" onChange={(e) => setUsername(e.target.value)} />
                        <label for="username">Password</label>
                        <input type="password" placeholder="Password" id="password" onChange={(e) => setPassword(e.target.value)} />
                        <button type='button' onClick={() => {
                            login(username, password)
                        }}>Log In</button>
                    </form>

                </Col>

            </Row>


        </div>
    )
}