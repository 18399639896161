import axios from "axios";
import { useNavigate } from "react-router-dom";
import { saveSession } from "../utils/user_session";
import jwt_decode from "jwt-decode";

const baseURL = "https://sumiapi.erparasrai.com.np/api/";
// "http://localhost:8000/api/";
export const getHeader = (useToken) => {
  if (useToken) {
    return {
      "Content-Type": "application/json",
      Authorization: `JWT ${localStorage.getItem("access")}`,
    };
  } else {
    return {
      "Content-Type": "application/json",
    };
  }
};

const refreshToken = async () => {
  try {
    let response = await axios.post(`${baseURL}refresh/`, {
      refresh: localStorage.getItem("refresh"),
    });
    if (response.status === 200) {
      if (response.data.hasOwnProperty("access")) {
        localStorage.setItem("access", response.data["access"]);
        return true;
      } else {
        let refresh = response.data["refresh"];
        axios
          .post(`${baseURL}refresh/`, { refresh: refresh })
          .then((response) => {
            if (response.status === 200) {
              localStorage.setItem("access", response.data["access"]);
              localStorage.setItem("refresh", refresh);
              return true;
            }
          });
      }
    } else {
      localStorage.clear();
      useNavigate()("/login/", { replace: true });
    }
  } catch (error) {
    localStorage.clear();
    window.location.href = "/login/";
  }
  return false;
};
export const SetupInterceptors = (http) => {
  http.interceptors.request.use(
    async (config) => {
      if (localStorage.getItem("access") !== null) {
        var decoded = jwt_decode(localStorage.getItem("access"));
        let currentDate = Date.now();
        if (decoded.exp - 30 < currentDate) {
          await refreshToken();
        }
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
  http.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response) {
        if (
          localStorage.getItem("access") !== null &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          let isRefreshed = await refreshToken();
          if (isRefreshed) {
            originalRequest.headers["Authorization"] =
              "JWT " + localStorage.getItem("access");
            return axios(originalRequest);
          }
        }
        if (error.response.status !== 401 && error.response.status !== 403) {
          return Promise.reject(error.response.data);
        }
      }
      return Promise.reject(error);
    }
  );
};
const api = axios.create({
  baseURL: baseURL,
  timeout: 1000,
});
SetupInterceptors(api);

export default api;
