import React, { useEffect, useState } from "react";

import { Col, Container, Row } from "react-bootstrap";
import BlogCard from "../components/blog_card";

import { toast } from "react-toastify";
import ReactTooltip from "react-tooltip";
import FilterButton from "../components/filter_button";
import api, { getHeader } from "../utils/api";
import PaginationBox from "../components/pagination_box";

export default function BlogPage() {
  const [filter, setFilter] = useState("all");
  return (
    <div className="w-100">
      <ReactTooltip id="article-tooltip" />
      <Container
        className="w-100 py-2 sticky-top"
        style={{ backgroundColor: "transparent" }}
      >
        <div class="btn-group" role="group">
          <FilterButton
            value="All"
            isSelected={filter === "all"}
            onClick={() => {
              setFilter("all");
            }}
          />
          <FilterButton
            value="Featured"
            isSelected={filter === "featured"}
            onClick={() => {
              setFilter("featured");
            }}
          />
          <FilterButton
            value="Removed"
            isSelected={filter === "removed"}
            onClick={() => {
              setFilter("removed");
            }}
          />
        </div>
      </Container>
      <BlogGrid filter={filter} />
    </div>
  );
}

const BlogGrid = (props) => {
  const [page, setPage] = useState(1);

  const [blogs, setBlogs] = useState([]);
  const [pagination, setPagination] = useState({ current: 0, total: 0 });

  const fetchData = (page) => {
    api
      .get(`blog/admin/${props.filter}/?page=${page}`)
      .then((response) => {
        var data = response.data;
        let blogs = [];
        data.blogs.forEach((row) => {
          blogs.push({
            id: row.id,
            slug: row.slug,
            title: row.title,
            coverImage: row.cover_image,
            body: row.body,
            modifiedDate: row.modifiedAt,
            isFeatured: row.isFeatured,
            isRemoved: row.isRemoved,
          });
        });
        setBlogs(blogs);
        setPagination({ current: data.page, total: data.total_pages });
      })
      .catch((e) => {
        setBlogs([]);
      });
  };

  useEffect(() => {
    fetchData(page);
  }, [page]);

  useEffect(() => {
    fetchData(1);
  }, [props.filter]);

  const onRemove = (id, state) => {
    if (window.confirm("Are you sure?") === true) {
      api
        .put(
          `blog/remove/${id}/`,
          {
            is_removed: !state,
          },
          { headers: getHeader(true) }
        )
        .then((response) => {
          if (response.status === 200) {
            toast.clearWaitingQueue();
            toast.success(response.data, {
              theme: "colored",
              pauseOnFocusLoss: false,
              pauseOnHover: false,
              autoClose: 1300,
              closeOnClick: true,
            });
            fetchData(page);
          }
        });
    }
  };

  const onFeatureChange = (id, isFeatured) => {
    api
      .put(
        `blog/edit/featured/${id}/`,
        {
          is_featured: !isFeatured,
        },
        { headers: getHeader(true) }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.clearWaitingQueue();
          toast.success(response.data, {
            theme: "colored",
            pauseOnFocusLoss: false,
            pauseOnHover: false,
            autoClose: 1300,
            closeOnClick: true,
          });
          fetchData(page);
        }
      });
  };
  return (
    <Container className="w-100">
      <Row className="py-4">
        {blogs.map((blog) => {
          return (
            <Col className="p-2" lg={4} md={6} sm={12}>
              <BlogCard
                blog={blog}
                onRemove={onRemove}
                onFeatureChange={onFeatureChange}
              />
            </Col>
          );
        })}
      </Row>
      <PaginationBox
        current={pagination.current - 1}
        total={pagination.total}
        onPageChange={({ selected }) => setPage(selected + 1)}
      />
    </Container>
  );
};
