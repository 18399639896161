import React from "react";

import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";

import "../assets/css/navbarstyle.css";
import ReactTooltip from "react-tooltip";
import { getUserDetaiil } from "../models/user_model";

import { useSelector, useDispatch } from "react-redux";
import { fetchUserDetail } from "../app/profile_reducer";
import { ThemeContext } from "../context_api/theme_context";

function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}

const ThemeToggleSwitch = (props) => {
  return (
    <button
      type="button"
      className={
        "btn mr-2 " +
        (props.isSelected ? " btn-success" : " btn-outline-success")
      }
      onClick={props.onClick}
      style={{ fontSize: 12 }}
    >
      {props.children}
    </button>
  );
};
export default function TopBar(props) {
  const dispatch = useDispatch();

  const profile = useSelector((state) => state.profile);
  const location = useLocation();
  const [pagename, setPagename] = useState("");
  const getPageName = (pathname) => {
    let path = pathname.replace(/^\/|\/$/g, "");
    return capitalize(path);
  };

  useEffect(() => {
    setPagename(getPageName(location.pathname));
  }, [location]);

  useEffect(async () => {
    let user = await getUserDetaiil();
    dispatch(fetchUserDetail(user));
  }, []);
  if (pagename === "") {
    return <></>;
  }
  return (
    <div className="position-sticky">
      <Container className="py-4 ">
        <div className="d-flex justify-content-between">
          <div>
            <section id="dashboadtxt" style={{ textTranform: "capitalize" }}>
              {pagename}
            </section>
            <section className="my-2" id="greet">
              Hello, @{profile.username}
            </section>
          </div>

          <div
            className="d-xs-none d-flex align-items-center  font-weight-bold"
            style={{ fontSize: 10, fontWeight: "bold" }}
          >
            <div className="px-3 text-center">
              Theme:
              <br />
              <ThemeContext.Consumer>
                {({ theme, switchTheme }) => (
                  <div class="btn-group btn-group-sm py-2" role="group">
                    <ThemeToggleSwitch
                      isSelected={theme === "light"}
                      onClick={() => {
                        switchTheme("light");
                      }}
                    >
                      Light
                    </ThemeToggleSwitch>
                    <ThemeToggleSwitch
                      isSelected={theme === "dark"}
                      onClick={() => {
                        switchTheme("dark");
                      }}
                    >
                      Dark
                    </ThemeToggleSwitch>
                  </div>
                )}
              </ThemeContext.Consumer>
            </div>
            <Profile image={profile.profileimage} />
          </div>
        </div>
      </Container>
    </div>
  );
}

const Profile = ({ image }) => {
  return (
    <Link to="profile/">
      <ReactTooltip
        id="profileimage-tooltip"
        data-type="success"
        effect="float"
      />
      <div
        className="profilebox"
        data-tip="View profile"
        data-for="profileimage-tooltip"
      >
        <img alt="Profile" className="profileimage" src={image} />
      </div>
    </Link>
  );
};
