import React from 'react';
import '../assets/css/homestyle.css';
import HomeGreeting from '../components/home_greeting';
import { Container } from 'react-bootstrap';


export default function HomePage() {

    return <Container>
        <HomeGreeting />
    </Container>


}   