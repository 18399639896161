import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import ReactImagePickerEditor from "react-image-picker-editor";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Popup from "reactjs-popup";
import "../assets/css/profile.css";
import { getUserDetaiil } from "../models/user_model";
import api, { getHeader } from "../utils/api";
import DataURIToBlob from "../utils/image_to_blob";

import { useDispatch } from "react-redux";
import { fetchUserDetail } from "../app/profile_reducer";

export default function ProfilePage() {
  const [user, setUser] = useState(null);
  const dispatch = useDispatch();
  let { id } = useParams();

  const fetchProfile = async () => {
    let userDetail;
    if (id === null) {
      userDetail = await getUserDetaiil();
      dispatch(fetchUserDetail(userDetail));
    } else {
      let user = {
        username: "",
        firstname: "",
        lastname: "",
        email: "",
        profileimage: "",
        dpimage: "",
        description: "",
        isSelf: false,
      };
      let response = await api.get(`profile/detail/?id=${id}`, {
        headers: getHeader(true),
      });
      if (response.status === 200) {
        let data = response.data;
        user = {
          username: data.username,
          firstname: data.first_name,
          lastname: data.last_name,
          email: data.email,
          profileimage: data.profileimage,
          dpimage: data.dpimage,
          description: data.description,
          isSelf: data.is_self,
        };
      }
      userDetail = user;
    }
    setUser(userDetail);
  };
  useEffect(() => {
    fetchProfile();
  }, []);
  if (user === null) {
    return <></>;
  }
  return (
    <Container>
      <Container className="my-3">
        <div className="d-lg-inline-flex">
          <div className="">
            <Image
              className="profileimg"
              src={user.profileimage}
              alt="profile image"
              height={300}
              width={300}
              style={{ borderColor: "#555", objectFit: "cover" }}
            />
          </div>
          <div style={{ width: 100, height: 30 }} />
          <div className="flex" style={{ minWidth: 200 }}>
            <div className="nametitle">
              {user.firstname} {user.lastname}
            </div>
            <span className="username">@{user.username}</span>
            <p className="info mt-2">{user.description}</p>
          </div>
        </div>
      </Container>
      {user.isSelf ? (
        <Popup
          trigger={
            <button className="btn btn-outline-primary btn-sm">Edit</button>
          }
          modal
          closeOnEscape
          overlayStyle={{ zIndex: 9999 }}
          contentStyle={{
            maxWidth: 400,
            backgroundColor: "#fff",
            borderRadius: 6,
          }}
          position="right center"
        >
          {(close) => (
            <ProfileEdit
              close={close}
              firstname={user.firstname}
              lastname={user.lastname}
              image={user.profileimage}
              description={user.description}
              onUpdated={() => {
                fetchProfile();
              }}
            />
          )}
        </Popup>
      ) : (
        <></>
      )}
    </Container>
  );
}

const ProfileEdit = ({
  close,
  firstname,
  lastname,
  image,
  description,
  onUpdated,
}) => {
  const [firstName, setFirstName] = useState(firstname);
  const [lastName, setLastName] = useState(lastname);
  const [descript, setDescript] = useState(description);

  const [selectedImage, setImage] = useState(null);

  const dispatch = useDispatch();

  const saveEdit = () => {
    let formdata = new FormData();
    formdata.append("first_name", firstName);
    formdata.append("last_name", lastName);
    formdata.append("description", descript);
    if (selectedImage != null) {
      const image = DataURIToBlob(selectedImage);
      formdata.append(
        "image",
        image,
        new Date()
          .toISOString()
          .replaceAll("-", "")
          .replaceAll("Z", "")
          .replaceAll("T", "_")
          .replaceAll(":", "")
          .replaceAll(".", "") + ".jpg"
      );
    }
    api
      .put("profile/detail/", formdata, { headers: getHeader(true) })
      .then(async (response) => {
        if (response.status === 200) {
          close();
          onUpdated();
          toast.success(response.data);
          let user = await getUserDetaiil();
          dispatch(fetchUserDetail(user));
        }
      });
  };
  return (
    <div className="p-2" style={{ maxWidth: 400 }}>
      <div className="px-2">
        <div className="text-danger fw-bold mb-3">Edit</div>
        <div className="w-100 text-center">
          <ReactImagePickerEditor
            config={{
              language: "en",
              width: 180,
              aspectRatio: 1,
              objectFit: "cover",
              compressInitial: null,
              borderWidth: 3,
              borderRadius: "0px 50px",
            }}
            imageSrcProp={image}
            imageChanged={(newDataUri) => {
              setImage(newDataUri);
            }}
          />
        </div>
        <Row className="my-2">
          <Col>
            <input
              className="editinput"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Col>
          <Col>
            <input
              className="editinput"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </Col>
        </Row>

        <textarea
          className="editinput"
          rows={6}
          onChange={(e) => setDescript(e.target.value)}
        >
          {description}
        </textarea>

        <Row className="mt-2 px-2">
          <Col
            className="btn-sm btn btn-outline-danger mx-1"
            onClick={() => {
              close();
            }}
          >
            Cancel
          </Col>
          <Col
            className="btn-sm btn btn-outline-success mx-1"
            onClick={() => {
              // close();
              saveEdit();
            }}
          >
            Save
          </Col>
        </Row>
      </div>
    </div>
  );
};
