import React from "react";
import { useEffect, useState } from "react";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { Container, Form, InputGroup } from "react-bootstrap";

import BlotFormatter from "quill-blot-formatter";

import ReactImagePickerEditor from "react-image-picker-editor";
import "react-image-picker-editor/dist/index.css";
import "../assets/css/blogdetailstyle.css";
import { useNavigate, useParams } from "react-router-dom";
import api, { getHeader } from "../utils/api";
import { toast } from "react-toastify";
import DataURIToBlob from "../utils/image_to_blob";
import { useQuery } from "react-query";
import { fetchCategories } from "../fetches/all_categories_fetch";
import { ThemeContext } from "../context_api/theme_context";
import { Comments, FacebookProvider } from "react-facebook";

export default function BlogDetailPage() {
  const navigate = useNavigate();
  const { quill, quillRef, Quill } = useQuill({
    modules: { blotFormatter: {} },
  });
  const [initialData, setInitial] = useState({
    id: null,
    image: null,
    title: null,
    body: null,
    isFeatured: false,
  });
  const [title, setTitle] = useState();
  const [imageurl, setUrl] = useState();
  const [selectedImage, setImage] = useState(null);

  const [isSaving, setSaving] = useState(false);
  const [category, setCategory] = useState(0);

  const categories = useQuery("all-categories", () => fetchCategories());
  if (Quill && !quill) {
    Quill.register("modules/blotFormatter", BlotFormatter);
  }

  useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldContents) => {
        quill.getContents();
      });
    }
  }, [quill, Quill]);

  const { slug } = useParams();
  useEffect(() => {
    if (slug != null) {
      api.get(`blog/${slug}/detail/?admin=true`).then((response) => {
        let data = response.data;
        setInitial({
          id: data.id,
          image: data.cover_image,
          title: data.title,
          body: data.body,
          isFeatured: data.isFeatured,
        });
        setUrl(data.cover_image);
        setTitle(data.title);
        setCategory(data.category);
        if (quill) {
          quill.clipboard.dangerouslyPasteHTML(data.body);
        }
      });
    }
  }, [quill]);

  const resetData = () => {
    setUrl(initialData.image);
    setTitle(initialData.title);
    if (quill) quill.clipboard.dangerouslyPasteHTML(initialData.body);
  };

  const saveData = () => {
    setSaving(true);
    let formdata = new FormData();
    if (initialData.id != null) formdata.append("id", initialData.id);
    formdata.append("title", title);
    formdata.append("body", quill.root.innerHTML);
    if (category == null) {
      formdata.append("category", 0);
    } else {
      formdata.append("category", category);
    }
    if (selectedImage != null) {
      const image = DataURIToBlob(selectedImage);
      formdata.append(
        "image",
        image,
        new Date()
          .toISOString()
          .replaceAll("-", "")
          .replaceAll("Z", "")
          .replaceAll("T", "_")
          .replaceAll(":", "")
          .replaceAll(".", "") + ".jpg"
      );
    }
    api
      .post("blog/blog/detail/", formdata, { headers: getHeader(true) })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data);
          setSaving(false);
          if (initialData.id == null) {
            navigate("/articles/", { replace: true });
          }
        }
      })
      .catch((error) => {
        setSaving(false);
      });
  };

  return (
    <Container>
      <ReactImagePickerEditor
        config={{
          borderRadius: "0px",
          language: "en",
          width: "100%",
          aspectRatio: 600 / 200,
          objectFit: "cover",
          compressInitial: null,
        }}
        imageSrcProp={imageurl}
        imageChanged={(newDataUri) => setImage(newDataUri)}
      />
      <input
        className="title-input w-100 my-2"
        placeholder="Title"
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
      />
      <hr />

      <InputGroup className="mb-3" style={{ width: 300 }}>
        <InputGroup.Text id="category">Category</InputGroup.Text>
        <Form.Select
          placeholder="Select Category"
          name="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value={0}>None</option>

          {categories.isLoading ? (
            <></>
          ) : (
            categories.data.map((category) => {
              return <option value={category.id}>{category.name}</option>;
            })
          )}
        </Form.Select>
      </InputGroup>
      <hr />
      <div ref={quillRef} className="editor_body" />
      <div className="my-5  d-flex justify-content-center">
        <button
          className="btn btn-outline-success"
          disabled={isSaving}
          onClick={isSaving ? null : saveData}
        >
          {isSaving ? "Saving....." : "Save"}
        </button>
        <span className="mx-3" />
        <button className="btn btn-outline-danger" onClick={resetData}>
          Reset
        </button>
      </div>
      <hr />
      <ThemeContext.Consumer>
        {({ theme, switchTheme }) => (
          <FacebookProvider appId="760659952340736">
            <Comments
              colorScheme={theme}
              href={`http://www.facebook.com/article=${slug}`}
              lazy
            />
          </FacebookProvider>
        )}
      </ThemeContext.Consumer>
    </Container>
  );
}
