import api, { getHeader } from "../utils/api";

export async function getUserDetaiil() {
    let user = {
        username: "",
        firstname: "",
        lastname: "",
        email: "",
        profileimage: "",
        dpimage: "",
        description: "",
        isSelf: false,
    }
    let response = await api.get("profile/detail/",
        { headers: getHeader(true), },
    );
    if (response.status === 200) {
        let data = response.data;
        user = {
            username: data.username,
            firstname: data.first_name,
            lastname: data.last_name,
            email: data.email,
            profileimage: data.profileimage,
            dpimage: data.dpimage,
            description: data.description,
            isSelf: data.is_self
        }

    }
    return user;
}