import React from "react";
import "./App.css";
import "animate.css";
import LoginPage from "./pages/login_page";
import BlogPage from "./pages/blog_page";

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./pages/home_page";

import BlogDetailPage from "./pages/blog_detail_page";
import { ToastContainer } from "react-toastify";
import Header from "./components/header";
import Dashboard from "./pages/dashboard_page";
import { Container } from "react-bootstrap";
import PageNotFound from "./pages/page_not_found";
import TopBar from "./components/navbar";
import SettingsPage from "./pages/settings";
import ProfilePage from "./pages/profile_page";
import { ProfileFormPage } from "./pages/profile_form_page";
import { isSessionExist, refreshTokenAtStart } from "./utils/user_session";
import UsersPage from "./pages/users_page";
import { ThemeContext } from "./context_api/theme_context";
import useLocalStorage from "use-local-storage";
import SocialsPage from "./pages/socials";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

function App() {
  const defaultDark = window.matchMedia("(prefers-color-scheme: dark)").matches;
  const [theme, setTheme] = useLocalStorage(
    "theme",
    defaultDark ? "dark" : "light"
  );
  const switchTheme = (newTheme) => {
    setTheme(newTheme);
  };

  const isAuth = () => {
    refreshTokenAtStart();
    return isSessionExist();
  };
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeContext.Provider value={{ theme: theme, switchTheme: switchTheme }}>
        <ThemeContext.Consumer>
          {({ theme }) => (
            <div
              className="body"
              style={{ overflow: "inherit" }}
              data-theme={theme}
            >
              <BrowserRouter>
                <Routes>
                  <Route
                    exact
                    path="/login/"
                    element={
                      isAuth() ? (
                        <Navigate replace to="/dashboard/" />
                      ) : (
                        <LoginPage />
                      )
                    }
                  />
                  <Route
                    exact
                    path="/"
                    element={
                      isAuth() ? (
                        <Navigate replace to="/dashboard/" />
                      ) : (
                        <Navigate replace to="/login/" />
                      )
                    }
                  />
                </Routes>

                {isAuth() ? (
                  <div className="d-flex flex-row">
                    <Header />
                    <section
                      className="w-100 d-flex flex-column content-body"
                      style={{
                        height: "100vh",
                        flexGrow: 1,
                      }}
                    >
                      <Container>
                        <TopBar />
                      </Container>
                      <div className="flex-grow" style={{ overflowY: "auto" }}>
                        <Container>
                          <Routes>
                            <Route
                              exact
                              path="/dashboard/"
                              element={
                                isAuth() ? (
                                  <Dashboard />
                                ) : (
                                  <Navigate replace to="/login/" />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/home/"
                              element={
                                isAuth() ? (
                                  <HomePage />
                                ) : (
                                  <Navigate replace to="/login/" />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/settings/"
                              element={
                                isAuth() ? (
                                  <SettingsPage />
                                ) : (
                                  <Navigate replace to="/login/" />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/socials/"
                              element={
                                isAuth() ? (
                                  <SocialsPage />
                                ) : (
                                  <Navigate replace to="/login/" />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/articles/"
                              element={
                                isAuth() ? (
                                  <BlogPage />
                                ) : (
                                  <Navigate replace to="/login/" />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/create/"
                              element={
                                isAuth() ? (
                                  <BlogDetailPage />
                                ) : (
                                  <Navigate replace to="/login/" />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/profile/"
                              element={
                                isAuth() ? (
                                  <ProfilePage />
                                ) : (
                                  <Navigate replace to="/login/" />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/users/"
                              element={
                                isAuth() ? (
                                  <UsersPage />
                                ) : (
                                  <Navigate replace to="/login/" />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/user/:id/"
                              element={
                                isAuth() ? (
                                  <ProfilePage />
                                ) : (
                                  <Navigate replace to="/login/" />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/profileform/"
                              element={
                                isAuth() ? (
                                  <ProfileFormPage />
                                ) : (
                                  <Navigate replace to="/login/" />
                                )
                              }
                            />
                            <Route
                              exact
                              path="/article/:slug/"
                              element={
                                isAuth() ? (
                                  <BlogDetailPage />
                                ) : (
                                  <Navigate replace to="/login/" />
                                )
                              }
                            />
                            <Route
                              path="/*"
                              element={
                                isAuth() ? (
                                  <PageNotFound />
                                ) : (
                                  <Navigate replace to="/login/" />
                                )
                              }
                            />
                          </Routes>
                        </Container>
                      </div>
                    </section>
                  </div>
                ) : (
                  <></>
                )}
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </BrowserRouter>
            </div>
          )}
        </ThemeContext.Consumer>
      </ThemeContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
