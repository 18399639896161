import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "../assets/css/dashboard.css";

import view from "../assets/icons/view.png";

import { PieChart } from "react-minimal-pie-chart";
import api from "../utils/api";
import { Link } from "react-router-dom";
import { useQuery } from "react-query";

const dashboardApiFetch = () => {
  return api.get("blog/dashboard/").then((response) => {
    var data = response.data;
    let list = [];
    data.popular_list.forEach((popular) => {
      list.push({
        title: popular.title,
        visits: popular.visits,
        image: popular.cover_image,
        isFeatured: popular.isFeatured,
        createdDate: popular.createdAt,
        slug: popular.slug,
      });
    });
    let chartColors = ["#2979FF", "#3F51B5", "#FFA000", "#64dd17", "#dd2c00"];
    let chartList = [];
    let index = 0;
    data.chart.visits.forEach((visit) => {
      chartList.push({
        title: "",
        name: visit.name,
        value: visit.visit,
        color: chartColors[index++],
      });
    });
    return {
      popular: list,
      totalArticles: data.chart.total_blogs,
      totalViews: data.chart.total,
      chartViews: chartList,
    };
  });
};

export default function Dashboard() {
  const { isLoading, data } = useQuery("dasboard", dashboardApiFetch);

  return (
    <div className="py-3">
      <div className="my-4 d-flex">
        <div className="summary">
          <div className="summary-value">
            {isLoading ? "-" : data.totalViews}
          </div>
          <div className="summary-title">Total Views</div>
        </div>
        <div className="summary">
          <div className="summary-value">
            {isLoading ? "-" : data.totalArticles}
          </div>
          <div className="summary-title">Total Articles</div>
        </div>
      </div>

      <div>
        <Row>
          <Col lg={5} md={12}>
            <div style={{ height: 420 }}>
              <PieChart
                lineWidth={12}
                animate={true}
                radius={30}
                animationDuration={800}
                data={isLoading ? [] : data.chartViews}
                rounded={true}
                labelPosition={70}
                labelStyle={{ fontSize: 2, scale: 2 }}
                label={({ dataEntry }) =>
                  `${dataEntry.title} (${Math.round(dataEntry.percentage)}%)`
                }
                paddingAngle={8}
              />
            </div>
            <Container className="d-flex flex-wrap">
              {isLoading ? (
                <></>
              ) : (
                data.chartViews.map((chartData) => {
                  return (
                    <ChartInfo
                      color={chartData.color}
                      name={chartData.name}
                      percentage={chartData.value / data.totalViews}
                    />
                  );
                })
              )}
            </Container>
          </Col>
          <Col lg={7} md={12}>
            <section className="popular-article">Popular Articles</section>
            {isLoading ? (
              <></>
            ) : (
              data.popular.map((popular) => {
                return <PopularTile data={popular} />;
              })
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
}

const PopularTile = (props) => {
  const data = props.data;
  return (
    <Link to={"/article/" + data.slug} style={{ textDecoration: "none" }}>
      <section className="popular-article-item d-flex  align-items-center py-2">
        <Image src={data.image} className="article-image" alt="Article Image" />
        <Col className="title-txt mx-4">
          <div className="popular-article-title">{data.title}</div>
          <div className="popular-article-date">{data.createdAt}</div>
        </Col>
        <div className="featured-box">Featured</div>
        {/* <div className="views-txt"><img src={comments} width={16} /> 104</div> */}
        <div className="views-txt">
          <Image alt="Views" src={view} width={16} /> {data.visits}
        </div>
      </section>
    </Link>
  );
};

const ChartInfo = (props) => {
  return (
    <div className="d-flex  align-items-center flex-row">
      <div
        className="chart-color-box"
        style={{ backgroundColor: props.color }}
      />
      <div className="chart-color-text">
        {props.name}({(props.percentage * 100).toFixed(0)}%)
      </div>
    </div>
  );
};
