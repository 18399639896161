import '../assets/css/page_not_found.css';
import React from 'react';
export default function PageNotFound() {
    return <div className='flex-grow' style={{ textAlign: "center", width: "100%" }}>
        <div className=" align-content-center align-content-center flex-wrap "  >
            <div class="status-text" style={{ width: "100%" }}>404</div>
            <div class="status-info" style={{ width: "100%" }}>
                Oops! The page you are looking for does not exist. It might have been moved or deleted.
            </div>
        </div>

    </div >
}