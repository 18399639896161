import React from 'react';

import { useEffect, useState } from "react";
import { Row, Col, Container, Image } from "react-bootstrap";
import 'react-image-picker-editor/dist/index.css'
import api, { getHeader } from "../utils/api";
import { toast } from "react-toastify";

import Dropzone from 'react-dropzone'


export function ImagePicker(props) {
    const selectedImage = props.selectedImage;
    const onChange = props.onChange
    const originalImage = props.originalImage

    const imageComponent = (getInputProps) => {
        if (selectedImage !== null) {
            return (<div className="image_picker_image align-items-center" >
                <Image src={URL.createObjectURL(selectedImage)}
                    height={350}
                    width={350}
                    alt="Display image"
                    style={{objectFit: "cover", borderRadius: 20 }}
                /></div>);

        } else if (originalImage !== null) {
            return (<div className="image_picker_image d-flex align-items-center" > <Image src={originalImage}
                height={350}
                width={350}
                alt="Display image"
                style={{  objectFit: "cover", borderRadius: 20 }}
            /></div>);
        }
        else {
            return (<div className="picker_text align-items-center" >
                <input {...getInputProps({
                    accept: {
                        'image/*': ['.jpg', '.jpeg', '.png', '.webp']
                    },
                })} />
                Drag 'n' drop some files here, or click to select files
            </div>
            )
        }
    }
    return (<Dropzone
        onDrop={files => onChange(files[0])}
        
        accept={{'image/*': ['.jpg', '.jpeg', '.png', '.webp']}}
        maxFiles={1}
    >
        {({ getRootProps, getInputProps, acceptedFiles }) => {
            return (<div {...getRootProps({ className: 'dropzone image_picker_box' })}>
                {imageComponent(getInputProps)}
                <div className="focus-overlay d-flex align-items-center text-center " >
                   Click or drag and drop image
                </div>
            </div>)
        }}</Dropzone>);
}


export default function HomeGreeting() {
    const [original, setOriginal] = useState({
        id: null,
        greet: "",
        position: "",
        imageurl: "",
    });
    const [greet, setGreet] = useState();
    const [position, setPosition] = useState();
    const [selectedImage, setImage] = useState(null);


    const [isEdit, setEdit] = useState(false);

    const checkEdit = () => {
        if (greet !== original.greet) return true;
        else if (position !== original.position) return true;
        else if (selectedImage !== null) return true;
        else return false;
    }
    useEffect(() => {
        let isedit = checkEdit();
        setEdit(isedit);
    }, [greet, position, selectedImage]);

    const loadData = () => {
        api.get("setting/greeting/").then((response) => {
            if (response.status === 200) {
                var data = response.data;
                let greet = data.greeting;
                let biodata = data.biodata;
                let image = data.cover_image;
                setGreet(greet);
                setPosition(biodata);
                setImage(null);
                setOriginal({
                    id: data.id,
                    greet: greet,
                    position: biodata,
                    imageurl: image,
                });
            }
        });
    }
    useEffect(() => {
        loadData();
    }, []);
    const save = () => {
        let formData = new FormData();
        formData.append('bio', position);
        formData.append('greeting', greet);
        if (selectedImage !== null)
            formData.append('image', selectedImage);
        if (original.id !== null)
            formData.append('id', original.id);
        toast.promise(api.post("setting/greeting/", formData, { headers: getHeader(true) }).then((value) => {
            loadData();
            return value;
        }), {
            pending: "Saving, please wait",
            success: "Information saved",
            error: "Error saving information",
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });

    }



    return (
        <div >
            <Row>
                <Col md={6} className="d-flex align-content-center flex-wrap px-5">
                    <textarea
                        className="greeting-input"
                        placeholder="Greeting"
                        defaultValue={greet}
                        type="textarea"
                        value={greet}
                        style={{ backgroundColor: "transparent", resize: "none" }}
                        onChange={(e) => setGreet(e.target.value)}
                    >{greet}
                    </textarea>
                    <textarea
                        className="d-flex personal-input"
                        placeholder="Title"
                        defaultValue={position}
                        type="text"
                        value={position}
                        onChange={(e) => setPosition(e.target.value)}
                        style={{ backgroundColor: "transparent", resize: "none", width: "100%" }}
                    ></textarea>
                    <div style={{ height: 200 }} />
                </Col>
                <Col md={6} >
                    <ImagePicker
                        originalImage={original.imageurl}
                        selectedImage={selectedImage}
                        onChange={imageFile => setImage(imageFile)}
                    />
                </Col>
            </Row>
            <Container className="my-4  d-flex justify-content-center">
                <button className="btn btn-outline-success btn-sm" disabled={!isEdit} onClick={() => save()} >Save</button>
                <span className="mx-3" />
                <button className="btn btn-outline-danger btn-sm" onClick={() => { loadData(); }} >Reset</button>
            </Container>
        </div >
    );



}