//import useState hook to create menu collapse state
import React, { useState, useEffect } from "react";

import logo from '../assets/images/logo.png';
import logodark from '../assets/images/logo-dark.png';
import smalllogo from '../assets/images/small_logo.ico';

//import react pro sidebar components
import {
    ProSidebar,
    Menu,
    MenuItem,
    SidebarHeader,
    SidebarFooter,
    SidebarContent
} from "react-pro-sidebar";
import {
    FiHome,
    FiLogOut,
    FiChevronsRight,
    FiChevronsLeft,
    FiGlobe
} from "react-icons/fi";
import {
    TbWriting,
} from "react-icons/tb";
import {
    MdOutlineCreateNewFolder,
    MdOutlineDashboard,
} from 'react-icons/md';
import {
    HiOutlineUsers
} from 'react-icons/hi';
//import sidebar css from react-pro-sidebar module and our custom css
import "react-pro-sidebar/dist/css/styles.css";
import "../assets/css/header.css";
import { useLocation, Link } from "react-router-dom";


import ReactTooltip from 'react-tooltip';


import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Col, Row } from "react-bootstrap";
import { ThemeContext } from "../context_api/theme_context";


const Header = () => {
    const location = useLocation();
    const [menuCollapse, setMenuCollapse] = useState(false);
    const menuIconClick = () => {
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };
    const [pagename, setPagename] = useState(location);
    useEffect(() => {
        if (pagename !== location.pathname) {
            setPagename(location.pathname);
        }
    }, [location]);

    return (
        <div id="header">

            {/* collapsed props to change menu size using menucollapse state */}
            <ProSidebar collapsed={menuCollapse} >
                <SidebarHeader>
                    <div className="logotext justify-content-center d-flex align-items-center" style={{ height: 67 }}>
                        {/* small and big change using menucollapse state */}
                        {menuCollapse ? <img alt="logo" src={smalllogo} width="40" /> :
                            <ThemeContext.Consumer>
                                {({ theme }) => <img alt="logo" src={theme === "light" ? logo : logodark} height="60" />}

                            </ThemeContext.Consumer>
                        }
                    </div>
                    <div className="closemenu" onClick={menuIconClick}>
                        {menuCollapse ? <FiChevronsRight /> : <FiChevronsLeft />}
                    </div>
                </SidebarHeader>
                <SidebarContent>
                    <ReactTooltip id="header-tooltip" data-type="success" effect="float" />
                    <Menu iconShape="square">
                        <Link to="/">
                            <MenuItem id="dashboard" active={pagename === "/dashboard/" || pagename === ""} icon={<MdOutlineDashboard />} data-tip="DASHBOARD" data-for='header-tooltip'>
                                DASHBOARD
                            </MenuItem>
                        </Link>
                        <Link to="/home/">
                            <MenuItem id="home" active={pagename === "/home/"} icon={<FiHome />} data-tip="HOME" data-for='header-tooltip'>
                                HOME
                            </MenuItem>
                        </Link>
                        <Link to="/create/">
                            <MenuItem id="create" active={pagename === "/create/"} icon={<MdOutlineCreateNewFolder />} data-tip="CREATE" data-for='header-tooltip'>
                                CREATE
                            </MenuItem>
                        </Link>
                        {/* <MenuItem id="article" active={pagename === "/article"} icon={<TbWriting />} data-tip="ARTICLE" data-for='header-tooltip'>
                            ARTICLE
                        </MenuItem> */}
                        <Link to="/articles/">
                            <MenuItem id="articles/" active={pagename === "/articles/"} icon={<TbWriting />} data-tip="ARTICLES" data-for='header-tooltip'>
                                ARTICLES
                            </MenuItem>
                        </Link>
                        <Link to="/users/">
                            <MenuItem id="users" active={pagename === "/users/"} icon={<HiOutlineUsers />} data-tip="USERS" data-for='header-tooltip'>
                                USERS
                            </MenuItem>
                        </Link>
                        {/* <Link to="/about/">
                            <MenuItem id="about/" active={pagename === "/about/"} icon={<MdOutlineInfo />} data-tip="ABOUT" data-for='header-tooltip'>
                                ABOUT
                            </MenuItem>
                        </Link> */}
                        <Link to="/socials/">
                            <MenuItem id="Social Media" active={pagename === "/socials/"} icon={<FiGlobe />} data-tip="SOCIAL" data-for='header-tooltip'>
                                SOCIALS
                            </MenuItem>
                        </Link>
                        {/* <Link to="/settings/">
                            <MenuItem id="settings" active={pagename === "/settings/"} icon={<MdOutlineSettings />} data-tip="SETTINGS" data-for='header-tooltip'>
                                SETTINGS
                            </MenuItem>
                        </Link> */}
                    </Menu>
                </SidebarContent>
                <SidebarFooter>
                    <Menu iconShape="square">
                        <Popup className="popup-bg" trigger={<MenuItem icon={<FiLogOut />}>Logout</MenuItem>} modal closeOnEscape overlayStyle={{ zIndex: 9999 }} contentStyle={{ maxWidth: 250, borderRadius: 6 }} position="right center">
                            {close => (<div className="p-2" style={{ maxWidth: 200 }}>
                                <div className="text-danger fw-bold">Sure to Logout?</div>
                                <div className="px-2">
                                    <Row className="mt-2">
                                        <Col className="btn-sm btn btn-outline-success mx-1" onClick={() => { close(); }} >Cancel</Col>
                                        <Col className="btn-sm btn btn-outline-danger mx-1" onClick={() => {
                                            close();
                                            localStorage.clear();
                                            window.location.href = "/login/";
                                        }} >Logout</Col>
                                    </Row>
                                </div>
                            </div>
                            )}
                        </Popup>
                    </Menu>
                </SidebarFooter>
            </ProSidebar>
        </div >
    );
};

export default Header;
